<template>

    <div id="ereceipt-form">

        <b-form @submit.stop.prevent="submit" v-bind:class="{ formOnly: isIframe }">

            <div class="register-form lekis-register-form">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="lekis-h1-title"><strong>Rezervovat přípravky<br /><span class="text-danger">z vašeho {{reservationAllowedString}}</span></strong></h1>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12" v-show="!isOnlyOneShownBusinessPremisse">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="lekis-input-group">
                                    <b-form-group id="igLocation">
                                        <h3>Vaše poloha</h3>
                                        <map-whisper-text-box :model.sync="deviceLocation" :location.sync="location"></map-whisper-text-box>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12" v-show="!isOnlyOneShownBusinessPremisse">
                        <div class="lekis-input-group">
                            <b-form-group id="igLocation">
                                <h3>Lékárna</h3>                                
                                <business-premise-select id="businessPremiseSelect"
                                                         :model.sync="$v.formData.idBusinessPremise.$model"
                                                         :location.sync="location"
                                                         :gdprUrl.sync="gdprUrl"
                                                         :disabled="formData.eDocuments.length > 0"
                                                         :eReceiptIsAllowed.sync="eReceiptIsAllowed"
                                                         :eVoucherIsAllowed.sync="eVoucherIsAllowed" />
                            </b-form-group>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <contact-details ref="contactDetailsRef" :formData.sync="formData" />
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <e-items :formData="formData" :eReceiptIsAllowed.sync="eReceiptIsAllowed" :eVoucherIsAllowed.sync="eVoucherIsAllowed"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <b-form-checkbox id="agreePolicy"
                                         v-model="$v.formData.agreePolicy.$model"
                                         name="agreePolicy"
                                         aria-describedby="agreePolicyFeedback"
                                         :state="validateState('agreePolicy')">
                            <strong>Souhlasím se zpracováním osobních údajů pro účely rezervace a seznámil jsem se se <a :href="gdprUrl" target="_blank">Zásadami zpracování osobních údajů a Podmínkami eRezervace</a>.</strong>
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 m-4">
                        <div id="dissabledWrappersaveUpdateButton" class="text-center">
                            <b-button id="saveUpdateBtn" type="submit" pill variant="danger" :disabled="isFormInvalid">
                                <span>Odeslat rezervaci</span>
                            </b-button>
                            <b-tooltip target="dissabledWrappersaveUpdateButton"
                                       triggers="hover" placement="bottom"
                                       v-if="$v.formData.$invalid">
                                Formulář není vyplněn validně nebo chybí souhlas se zpracováním osobních údajů.
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>

        <add-item-code-modal :eDocuments.sync="formData.eDocuments" :eReceiptIsAllowed.sync="eReceiptIsAllowed" :eVoucherIsAllowed.sync="eVoucherIsAllowed"></add-item-code-modal>
    </div>

</template>

<script>

    import { required, sameAs, minLength, minValue } from "vuelidate/lib/validators";
    import businessPremiseSelect from '@/components/bussinessPremiseSelect'
    import mapWhisperTextBox from '@/components/mapWhisperTextBox'
    import { geolocationService } from '@/services'
    import addItemCodeModal from '@/components/addItemCodeModal'
    import contactDetails from '@/components/contactDetails'
    import eItems from '@/components/eItems'

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('stylesheet');

    function addCss(fileName) {

        var head = document.head;
        var link = document.createElement("link");

        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = fileName;

        head.appendChild(link);
    }

    if (myParam != undefined && myParam != "") {
        try {
            addCss(myParam);
        } catch (_) {
            console.log("Zadaný parametr 'stylesheet' není URL adresa k css");
        }
    }

    export default {
        name: 'ereceiptForm',
        components: {
            businessPremiseSelect,
            addItemCodeModal,
            mapWhisperTextBox,
            contactDetails,
            eItems
        },
        props: {
            eDocuments: {
                type: Array,
                required: true
            }
        },
        data: function () {
            return {
                formData: {
                    fullName: null,
                    email: null,
                    phoneNumber: null,
                    eDocuments: this.eDocuments,
                    allowChangeMedicamentForCheaper: false,
                    allowReleaseAllReps: false,
                    agreePolicy: false,
                    idBusinessPremise: null
                },
                deviceLocation: "",
                location: null,
                gdprUrl: "https://www.lekis.cz/podpora/rezervace-ereceptu/",
                note: null,
                eReceiptIsAllowed: false,
                eVoucherIsAllowed: false
            }
        },
        computed: {
            isOnlyOneShownBusinessPremisse() {
                if (this.$route.query.provozovny && this.$route.query.provozovny.includes(",") == false) {
                    return true;
                }

                return false;
            },
            isIframe() {
                if (this.$route.name == "formOnly") {
                    return true;
                }
                return false;
            },
            isFormInvalid() {
                let comContactDetails = this.$refs.contactDetailsRef;
                return this.$v.formData.$invalid || comContactDetails.$v.$invalid
            },
            reservationAllowedString() {
                if (!this.formData.idBusinessPremise) {
                    return "eReceptu / ePoukazu";
                }
                else if (this.eReceiptIsAllowed && this.eVoucherIsAllowed) {
                    return "eReceptu / ePoukazu";
                }
                else if (this.eReceiptIsAllowed && !this.eVoucherIsAllowed) {
                    return "eReceptu";
                }
                else if (!this.eReceiptIsAllowed && this.eVoucherIsAllowed) {
                    return " ePoukazu";
                }
                else if (!this.eReceiptIsAllowed && !this.eVoucherIsAllowed) { // tady je neco hodne spatne
                    return "Chyba: provozovna nemá povolené žádné eDokumenty";
                }

                return "";
            }
        },
        validations: {
            formData: {
                agreePolicy: { sameAs: sameAs(() => true), required },
                idBusinessPremise: { required, minValue: minValue(1) },
                note: {}
            }
        },
        mounted: function () {
            this.getLocationByGPS();
            this.formData.eDocuments = []; // pokaždé chceme vyprázdnit erecepty - občas se v iframe z nějakého důvodu nevypráznily, proto je zde toto
        },
        methods: {
            // pokusí se získat lokaci na základě GPS ze zařízení
            getLocationByGPS() {
                let thisHelper = this;
                geolocationService.getLocationByGPS(function (location) {
                    thisHelper.location = location;
                    if (location) {
                        geolocationService.getLocationAsString(location.coords.longitude, location.coords.latitude, function (result) {
                            thisHelper.deviceLocation = result.label;
                        });
                    }
                });
            },
            validateState(name) {
                const { $dirty, $error } = this.$v.formData[name];
                return $dirty ? !$error : null;
            },
            removeCodeFromList(item) {
                this.formData.eDocuments = _.filter(this.formData.eDocuments, function (currentObject) {
                    return currentObject.code != item.code;
                });
                this.$emit('update:eDocuments', this.formData.eDocuments)
            },
            submit() {
                if (this.isFormInvalid) {
                    vue.notify({
                        type: 'warn',
                        title: 'Chybně vyplněné údaje',
                        text: 'Vyplňte prosím všechny údaje a zkute to znovu.',
                        duration: 3000
                    });
                } else {

                    return this.$store.dispatch("reservation/createReservation", this.formData)
                        .then(result => {
                            this.$router.push({ name: 'summary' })
                            return result;
                        })
                        .catch(error => {
                            console.log('error: ', error)
                        });

                }
            }
        }
    }

</script>

<style scoped>

    .formOnly {
        background: white;
        padding: 3%;
    }

    .app {
        background: white;
    }

    .footer-text {
        color: black !important
    }
</style>