<template>

    <div>
        <b-form-group id="igPostalCode">
            <b-form-input id="postalCode"
                          name="postalCode"
                          placeholder="PSČ *"
                          v-model="dataInput"
                          :state.sync="validInput"
                          v-on:keyup="inputTextChange"
                          aria-describedby="postalCodeFeedback"
                          @keypress="isPostalCodeNumberChar($event)"></b-form-input>
            <b-form-invalid-feedback id="postalCodeFeedback">Musíte vyplnit validní PSČ.</b-form-invalid-feedback>
        </b-form-group>
    </div>

</template>

<script>

    export default {
        name: 'postalCodeFormInput',
        data: function () {
            return {
                localModel: this.model,
                localValid: this.valid
            }
        },
        props: {
            model: {
            },
            valid: {
            }
        },
        computed: {
            dataInput: {
                get: function () {
                    return this.model;
                },
                set: function (newValue) {
                    this.localModel = newValue
                }
            },
            validInput: {
                get: function () {
                    return this.valid;
                },
                set: function (newValue) {
                    this.localValid = newValue
                }
            }
        },
        mounted() {
        },
        methods: {
            inputTextChange() {
                this.$emit('update:model', this.localModel);
            },
            isPostalCodeNumberChar(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode < 48 || charCode > 57) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        }
    }

</script>