
export const geolocationService = {
    findLocationByString,
    getLocationAsString,
    getLocationByGPS,
    distanceInKmBetweenEarthCoordinatesInKm,
    distanceInKmBetweenEarthCoordinatesInM
};

// získá lokaci na základě zadaného textu
function findLocationByString(input, callbackFn) {

    if (!input) {
        return;
    }

    new SMap.Geocoder(input, response);

    function response(geocoder) { /* Odpověď */

        // v případě, že nejsou nalezeny žádné výsledky
        if (!geocoder.getResults()[0].results.length) {
            //alert("Je nám líto, ale vámi zadaná adresa nebyla nalezena.");
            return;
        }

        var results = geocoder.getResults()[0].results;
        var data = [];
        while (results.length) { /* Zobrazit všechny výsledky hledání */
            var item = results.shift()
            data.push(item);
        }
        callbackFn(data);
    }
}

// pokusí se získat lokaci na základě GPS ze zařízení
function getLocationByGPS(callbackFn) {

    if (!("geolocation" in navigator)) {
        thisHelper.errorStr = 'Geolokace není dostupná.';
        return;
    }

    let thisHelper = this;

    navigator.geolocation.getCurrentPosition(pos => {
        callbackFn(pos);
    }, err => {
        if (err.code == 1) {
            console.log("Uživatel nechce sdílet svoji lokaci.")
        }
        thisHelper.errorStr = err.message;
    })


}

// získá lokaci na základě zadaných souřadnic
function getLocationAsString(longitude, latitude, callbackFn) {
    var response = function (geocoder) {
        callbackFn(geocoder.getResults());
    }

    var coords = SMap.Coords.fromWGS84(longitude, latitude);
    new SMap.Geocoder.Reverse(coords, response);

}

// převede stupně na radiány
function degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
}

// vypočítá vzdálenost mezi dvěmi souřadnicemi v km
function distanceInKmBetweenEarthCoordinatesInKm(lat1, lon1, lat2, lon2) {
    var earthRadiusKm = 6371;

    var dLat = degreesToRadians(lat2 - lat1);
    var dLon = degreesToRadians(lon2 - lon1);

    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
}

// vypoítá vzdálenost mezi dvěma souřadnicemi v m
function distanceInKmBetweenEarthCoordinatesInM(lat1, lon1, lat2, lon2) {
    return distanceInKmBetweenEarthCoordinatesInKm(lat1, lon1, lat2, lon2) * 1000.0;
}