<template>

    <div id="summaryPage">

        <div class="register">
            <div class="row align-items-center h-100">
                <div class="col-md-3 register-left d-sm-none d-md-block">

                    <div v-if="!configuration.logoUrl">
                        <img src="../assets/logo.png" class="img-fluid" />
                    </div>
                    <div v-else>
                        <img :src="configuration.logoUrl" class="img-fluid" />
                    </div>

                    <div v-if="!_.isEmpty(summaryData)" id="welcomeText" class="d-none d-sm-block">
                        <h3>Děkujeme</h3>
                    </div>
                    <div v-else>
                        <h3>Je nám líto...</h3>
                        <p>Tato data vám nyní nemůžeme zobrazit</p>
                    </div>

                </div>
                <div class="col-md-9 register-right">
                    <div class="row register-form">
                        <div class="col-md-12">
                            <default-order-summary v-if="!configuration.formViewName" :eDocuments.sync="sharedEDocuments"></default-order-summary>
                            <delivery-order-votice-summary v-else-if="configuration.formViewName == 'dorucovaniLeku'" :eDocuments.sync="sharedEDocuments"></delivery-order-votice-summary>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <footer class="text-center"><a href="https://www.lekis.cz" class="footer-text" target="_blank">2020 Lekis s.r.o.</a></footer>

    </div>

</template>

<script>

    import defaultOrderSummary from '@/components/summary/defaultOrderSummary'
    import deliveryOrderVoticeSummary from '@/components/summary/dorucovaniLekuVoticeSummary'

    export default {
        name: 'ereceptiFormPage',
        components: {
            defaultOrderSummary,
            deliveryOrderVoticeSummary
        },
        data: function () {
            return {
                sharedEDocuments: []
            }
        },
        computed: {
            configuration() {
                return this.$store.state.configuration.configuration.data;
            },
            summaryData() {
                return this.$store.state.reservation.createReservation.createdReservation;
            }
        },
        methods: {
        }
    }

</script>
