
import { reservationService } from '@/services'

const state = {
    createReservation: {
        status: {
            loading: false
        },
        createdReservation: {}
    }
}

const mutations = {

    createReservationRequest() {
        state.createReservation.status.loading = true;
    },
    createReservationSuccess(state, businessPremises) {
        state.createReservation.status.loading = false;
        state.createReservation.createdReservation = businessPremises;
    },
    createReservationFailure(state) {
        state.createReservation.status.loading = false;
        state.createReservation.createdReservation = {};
    }
}

const actions = {

    createReservation: async function ({ commit }, data) {
        commit('createReservationRequest');
        await reservationService.createReservation(data)
            .then(
                createdReservation => {
                    commit('createReservationSuccess', createdReservation);
                },
                error => {
                    commit('createReservationFailure', error);
                }
            );
    }

}

export const reservation = {
    namespaced: true,
    state,
    actions,
    mutations
};