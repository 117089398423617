
import { configurationService } from '@/services'

const state = {
    configuration: {
        status: {
            loading: false
        },
        data: []
    },
}

const mutations = {

    getConfigurationRequest() {
        state.configuration.status.loading = true;
    },
    getConfigurationSuccess(state, data) {
        state.configuration.status.loading = false;
        state.configuration.data = data;
    },
    getConfigurationFailure(state) {
        state.configuration.status.loading = false;
        state.configuration.data = [];
    },
}

const actions = {

    getConfiguration: async function ({ commit }) {
        commit('getConfigurationRequest');
        await configurationService.getConfiguration()
            .then(
                data => {
                    commit('getConfigurationSuccess', data);
                },
                error => {
                    commit('getConfigurationFailure', error);
                }
            );
    },

}

export const configuration = {
    namespaced: true,
    state,
    actions,
    mutations
};