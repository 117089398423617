<template>
    <div class="lekis-input-group">
        <h3>{{reservationAllowedString}}</h3>
        <b-table striped hover :items="formData.eDocuments" :fields="fields" small>
            <template v-slot:cell()="data">
                <span>{{data.item.eItemShortcut}}</span>:
                <span v-if="data.item.eItemShortcut == 'ERP'">{{data.item.code | ereceiptCode}}</span>
                <span v-if="data.item.eItemShortcut == 'EPO'">{{data.item.code}}</span>
            </template>
            <template v-slot:head(actions)="data">


                <div id="dissabledAddButton" class="text-center">
                    <b-button id="addCodeModalOpen" type="button" pill variant="primary" v-b-modal.modal-add-code :disabled="!formData.idBusinessPremise">
                        <i class="fa fa-plus"></i> Přidat
                    </b-button>
                    <b-tooltip target="dissabledAddButton"
                               triggers="hover" placement="bottom"
                               v-if="!formData.idBusinessPremise">
                        Musíte nejprve vybrat lékárnu.
                    </b-tooltip>
                </div>


            </template>
            <template v-slot:cell(actions)="data">
                <b-button id="removeCodeFromList" type="button" pill variant="danger" v-on:click="removeCodeFromList(data.item)">
                    <i class="fa fa-trash"></i>
                </b-button>
            </template>

            <template v-slot:head(code)="data">
                {{reservationAllowedString}}
            </template>

        </b-table>
        <b-form-group id="igAllowChangeMedicamentForCheaper" v-if="formData.eDocuments.length > 0" class="text-center">
            <b-form-checkbox id="allowChangeMedicamentForCheaper"
                             v-model="$v.formData.allowChangeMedicamentForCheaper.$model"
                             name="allowChangeMedicamentForCheaper">
                Možnost zaměnit léky za levnější generika
            </b-form-checkbox>
        </b-form-group>
        <b-form-group id="igAllowReleaseAllReps" v-if="formData.eDocuments.length > 0" class="text-center">
            <b-form-checkbox id="allowReleaseAllReps"
                             v-model="$v.formData.allowReleaseAllReps.$model"
                             name="allowReleaseAllReps">
                Vydat všechna opakování u opakovacích eReceptů
            </b-form-checkbox>
        </b-form-group>
    </div>
</template>

<script>
    import { required, sameAs, minLength, minValue } from "vuelidate/lib/validators";

    export default {
        props: ['formData', 'eReceiptIsAllowed', 'eVoucherIsAllowed'],
        data: function () {
            return {
                fields: _.each([
                    { key: 'code', label: "Kód eReceptu / ePoukazu", sortable: false },
                    { key: 'actions', label: "Akce", sortable: false }
                ], function (item) {
                    item.tdClass = "align-middle";
                    item.class = "text-center";
                })
            }
        },
        validations: {
            formData: {
                eDocuments: { required, minLength: minLength(1) },
                allowChangeMedicamentForCheaper: {},
                allowReleaseAllReps: {},
            }
        },
        computed: {
            reservationAllowedString() {
                if (!this.formData.idBusinessPremise) {
                    return "eReceptu / ePoukazu";
                }
                if (this.eReceiptIsAllowed && this.eVoucherIsAllowed) {
                    return "eRecepty / ePoukazy";
                }
                else if (this.eReceiptIsAllowed && !this.eVoucherIsAllowed) {
                    return "eRecepty";
                }
                else if (!this.eReceiptIsAllowed && this.eVoucherIsAllowed) {
                    return " ePoukazy";
                }
                else if (!this.eReceiptIsAllowed && !this.eVoucherIsAllowed) { // tady je neco hodne spatne
                    return "Chyba: provozovna nemá povolené žádné eDokumenty";
                }

                return "";
            },
        },
        methods: {
            removeCodeFromList(item) {
                this.formData.eDocuments = _.filter(this.formData.eDocuments, function (currentObject) {
                    return currentObject.code != item.code;
                });
                this.$emit('update:eDocuments', this.formData.eDocuments)
            },
            validateState(ref) {
                const { $dirty, $error } = this.$v.formData[ref];
                this.valid = $dirty ? !$error : null;
                return $dirty ? !$error : null;
            },
            validateForm() {
                this.$v.formData.$touch();
                return !this.$v.formData.$invalid;
            }
        }
    }
</script>
