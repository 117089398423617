<template>

    <div id="ereceptiFormPage">

        <div class="row register" style="margin-left: 7%; margin-right: 7%; margin-top: 3%;">
            <div class="col-md-12 lekis-page-shadow">
                <div class="row">
                    <div class="col-md-3 register-left d-none d-sm-none d-md-block lekis-register-left">
                        <div v-if="!configuration.logoUrl">
                            <img src="../assets/logo.png" class="lekis-logo" />
                        </div>
                        <div v-else>
                            <img :src="configuration.logoUrl" class="custom-logo" />
                        </div>

                        <div id="welcomeText" class="d-none d-sm-block">
                            <img src="../assets/side-image.png" class="lekis-side-image" />
                        </div>
                    </div>

                    <div class="col-md-9 col-sm-12 register-right lekis-input-goup-background-color lekis-register-right">
                        <div v-if="!configuration.formViewName">
                            <default-ereceipt-form :eDocuments.sync="sharedEDocuments"></default-ereceipt-form>
                        </div>
                        <div v-else-if="'dorucovaniLeku'">
                            <delivery-order-votice-form :eDocuments.sync="sharedEDocuments"></delivery-order-votice-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer class="text-center"><a href="https://www.lekis.cz" class="footer-text" target="_blank">2020 Lekis s.r.o.</a></footer>

    </div>

</template>

<script>

    import defaultEreceiptForm from '@/components/forms/defaultEreceiptForm'
    import deliveryOrderVoticeForm from '@/components/forms/dorucovaniLekuVoticeEReceiptForm'

    export default {
        name: 'ereceptiFormPage',
        components: {
            defaultEreceiptForm,
            deliveryOrderVoticeForm
        },
        data: function () {
            return {
                sharedEDocuments: []
            }
        },
        computed: {
            configuration() {
                return this.$store.state.configuration.configuration.data;
            }
        },
        methods: {
        }
    }

</script>