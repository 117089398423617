import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Notifications from 'vue-notification'
import axios from 'axios'
import Vuex from 'vuex'
import AsyncComputed from 'vue-async-computed'
//import VueQrcodeReader from "vue-qrcode-reader";
import VSwitch from 'v-switch-case'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../public/original.css'
import '../public/lekis.css'

Vue.config.productionTip = false

import store from './store'
import router from './router'
import filters from './filters';


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(Notifications)
Vue.use(Vuex)
Vue.use(filters)
Vue.use(AsyncComputed)
//Vue.use(VueQrcodeReader);
Vue.use(VSwitch)

window._ = require('lodash');

store.dispatch('configuration/getConfiguration').then(function (data) {

    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#app')

});