
import Vue from 'vue'
import Vuex from 'vuex'

import { businessPremise } from '@/store/modules/businessPremise.module';
import { reservation } from '@/store/modules/reservation.module';
import { configuration } from '@/store/modules/configuration.module';

Vue.config.devtools = true
Vue.use(Vuex);

export default new Vuex.Store({

    modules: {
        businessPremise,
        reservation,
        configuration
    }

})
