<template>

    <div id="ereceptiFormPageForIframe">

        <div class="col-md-9 col-sm-12 register-right lekis-input-goup-background-color lekis-register-right">
            <div v-if="!configuration.formViewName">
                <default-ereceipt-form :eDocuments.sync="sharedEDocuments"></default-ereceipt-form>
            </div>
            <div v-else-if="'dorucovaniLeku'">
                <delivery-order-votice-form :eDocuments.sync="sharedEDocuments"></delivery-order-votice-form>
            </div>
        </div>
    </div>


</template>

<script>

    import defaultEreceiptForm from '@/components/forms/defaultEreceiptForm'
    import deliveryOrderVoticeForm from '@/components/forms/dorucovaniLekuVoticeEReceiptForm'

    export default {
        name: 'ereceptiFormPage',
        components: {
            defaultEreceiptForm,
            deliveryOrderVoticeForm
        },
        data: function () {
            return {
                sharedEDocuments: []
            }
        },
        computed: {
            configuration() {
                return this.$store.state.configuration.configuration.data;
            }
        },
        methods: {
        }
    }

</script>