
import axios from 'axios'

const baseUrl = '/api/EItemCodeValidator/';

export const eitemCodeValidatorService = {
    isEItemCodeValid
};

async function isEItemCodeValid(ereceiptCode) {
    return await axios.get(`${baseUrl}IsEItemCodeValid`, {
        params: {
            code: ereceiptCode
        }
    });
}