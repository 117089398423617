<template>

    <div id="ereceipt-form">

        <b-form @submit.stop.prevent="submit" v-bind:class="{ formOnly: isIframe }">

            <div class="register-form lekis-register-form">

                <div class="row">
                    <div class="col-md-12">
                        <h1 class="lekis-h1-title"><strong>Doručování léků<br /><span class="text-danger">Votice</span></strong></h1>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="lekis-input-group">
                                    <h3>Kontaktní údaje</h3>
                                    <full-name-input :model.sync="$v.formData.fullName.$model" :valid="validateState('fullName')"></full-name-input>
                                    <email-input :model.sync="$v.formData.email.$model" :valid="validateState('email')"></email-input>
                                    <phone-input :model.sync="$v.formData.phoneNumber.$model" :valid="validateState('phoneNumber')"></phone-input>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="lekis-input-group">
                                    <h3>Dodací údaje</h3>
                                    <street-input :model.sync="$v.formData.street.$model" :valid="validateState('street')"></street-input>
                                    <city-input :model.sync="$v.formData.city.$model" :valid="validateState('city')"></city-input>
                                    <postal-code-input :model.sync="$v.formData.postalCode.$model" :valid="validateState('postalCode')"></postal-code-input>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="lekis-input-group">
                                    <h3>Poznámka</h3>
                                    <b-form-group id="igNote">
                                        <b-form-textarea id="note"
                                                         name="note"
                                                         placeholder="Zde napište poznámku"
                                                         v-model="$v.formData.note.$model"></b-form-textarea>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="lekis-input-group">
                            <h3>eRecepty</h3>
                            
                            <b-table striped hover :items="formData.prescriptions" :fields="fields" small>
                                <template v-slot:cell()="data">
                                    <span>{{data.item.code | ereceiptCode}}</span>
                                </template>
                                <template v-slot:head(actions)="data">
                                    <b-button id="addCodeModalOpen" type="button" pill variant="primary" v-b-modal.modal-add-code>
                                        <i class="fa fa-plus"></i> Přidat
                                    </b-button>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <b-button id="removeCodeFromList" type="button" pill variant="danger" v-on:click="removeCodeFromList(data.item)">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                            </b-table>
                            <b-form-group id="igAllowChangeMedicamentForCheaper" v-if="formData.prescriptions.length > 0" class="text-center">
                                <b-form-checkbox id="allowChangeMedicamentForCheaper"
                                                 v-model="$v.formData.allowChangeMedicamentForCheaper.$model"
                                                 name="allowChangeMedicamentForCheaper">
                                    Možnost zaměnit léky za levnější generika
                                </b-form-checkbox>
                                <b-form-group id="igAllowReleaseAllReps" v-if="formData.prescriptions.length > 0" class="text-center">
                                    <b-form-checkbox id="allowReleaseAllReps"
                                                     v-model="$v.formData.allowReleaseAllReps.$model"
                                                     name="allowReleaseAllReps">
                                        Vydat všechna opakování u opakovacích eReceptů
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <b-form-checkbox id="agreePolicy"
                                         v-model="$v.formData.agreePolicy.$model"
                                         name="agreePolicy"
                                         aria-describedby="agreePolicyFeedback"
                                         :state="validateState('agreePolicy')">
                            <strong>Souhlasím se zpracováním osobních údajů pro účely objednávky a seznámil jsem se se <a :href="gdprUrl" target="_blank">Zásadami zpracování osobních údajů a Podmínkami eRezervace</a>.</strong>
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 m-4">
                        <div id="dissabledWrappersaveUpdateButton" class="text-center">
                            <b-button id="saveUpdateBtn" type="submit" pill variant="danger" :disabled="$v.formData.$invalid">
                                <span>Odeslat rezervaci</span>
                            </b-button>
                            <b-tooltip target="dissabledWrappersaveUpdateButton"
                                       triggers="hover" placement="bottom"
                                       v-if="$v.formData.$invalid">
                                Formulář není vyplněn validně nebo chybí souhlas se zpracováním osobních údajů.
                            </b-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
        
        <add-item-code-modal :prescriptions.sync="formData.prescriptions"></add-item-code-modal>

    </div>

</template>

<script>

    import { required, requiredIf, email, sameAs, maxLength, minLength, minValue } from "vuelidate/lib/validators";
    import businessPremiseSelect from '@/components/bussinessPremiseSelect'
    import mapWhisperTextBox from '@/components/mapWhisperTextBox'
    import addItemCodeModal from '@/components/addItemCodeModal'
    import phoneInput from '@/components/formComponents/phoneNumberFormInput'
    import emailInput from '@/components/formComponents/emailFormInput'
    import fullNameInput from '@/components/formComponents/fullNameFormInput'
    import streetInput from '@/components/formComponents/streetFormInput'
    import cityInput from '@/components/formComponents/cityFormInput'
    import postalCodeInput from '@/components/formComponents/postalCodeFormInput'

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('stylesheet');

    function addCss(fileName) {

        var head = document.head;
        var link = document.createElement("link");

        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = fileName;

        head.appendChild(link);
    }

    if (myParam != undefined && myParam != "") {
        try {
            addCss(myParam);
        } catch (_) {
            console.log("Zadaný parametr 'stylesheet' není URL adresa k css");
        }
    }

    export default {
        name: 'ereceiptForm',
        components: {
            businessPremiseSelect,
            addItemCodeModal,
            mapWhisperTextBox,
            phoneInput,
            emailInput,
            fullNameInput,
            streetInput,
            cityInput,
            postalCodeInput
        },
        props: {
            prescriptions: {
                type: Array,
                required: true
            }
        },
        data: function () {
            return {
                formData: {
                    fullName: null,
                    email: null,
                    phoneNumber: null,
                    street: null,
                    city: null,
                    postalCode: null,
                    prescriptions: this.prescriptions,
                    allowChangeMedicamentForCheaper: false,
                    allowReleaseAllReps: false,
                    agreePolicy: false,
                    idBusinessPremise: null,
                    note: null
                },
                fields: _.each([
                    { key: 'code', label: "Kód eReceptu", sortable: false },
                    { key: 'actions', label: "Akce", sortable: false }
                ], function (item) {
                    item.tdClass = "align-middle";
                    item.class = "text-center";
                }),
                deviceLocation: "",
                location: null,
                gdprUrl: "https://www.lekis.cz/podpora/rezervace-ereceptu/"
            }
        },
        computed: {
            isSpecificBussinessPremise() {
                if (this.$route.query.lekarny) {
                    return true;
                }

                return false;
            },
            isIframe() {
                if (this.$route.name == "formOnly") {
                    return true;
                }
                return false;
            },
            configuration() {
                return this.$store.state.configuration.configuration.data;
            }
        },
        validations: {
            formData: {
                fullName: { required },
                email: { required, email },
                phoneNumber: {
                    required,
                    isPhoneNumber(value) {
                        let phoneRegex = /^(\+\d{3})? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/;
                        let m = value.match(phoneRegex)
                        if (m) {
                            return true;
                        }

                        return false;
                    }
                },
                street: { required },
                city: { required },
                postalCode: {
                    required,
                    maxLength: maxLength(5),
                    minLength: minLength(5),
                },
                prescriptions: { required, minLength: minLength(1) },
                allowChangeMedicamentForCheaper: {},
                allowReleaseAllReps: {},
                agreePolicy: { sameAs: sameAs(() => true), required },
                idBusinessPremise: { required, minValue: minValue(1) },
                note: {}
            }
        },
        mounted: function () {
            this.formData.prescriptions = []; // pokaždé chceme vyprázdnit erecepty - občas se v iframe z nějakého důvodu nevypráznily, proto je zde toto
            this.$v.formData.idBusinessPremise.$model = this.configuration.businessPremiseIdFromSystemDB;
        },
        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.formData[name];
                return $dirty ? !$error : null;
            },
            removeCodeFromList(item) {
                this.formData.prescriptions = _.filter(this.formData.prescriptions, function (currentObject) {
                    return currentObject.code != item.code;
                });
                this.$emit('update:prescriptions', this.formData.prescriptions)
            },
            submit() {
                this.$v.$touch()
                if (this.$v.formData.$invalid) {
                    vue.notify({
                        type: 'warn',
                        title: 'Chybně vyplněné údaje',
                        text: 'Vyplňte prosím všechny údaje a zkute to znovu.',
                        duration: 3000
                    });
                } else {

                    return this.$store.dispatch("reservation/createReservation", this.formData)
                        .then(result => {
                            this.$router.push({ name: 'summary' })
                            return result;
                        })
                        .catch(error => {
                            console.log('error: ', error)
                        });

                }
            }
        }
    }

</script>

<style scoped>

    .formOnly {
        background: white;
        padding: 3%;
    }

    .app {
        background: white;
    }

    .footer-text {
        color: black !important
    }
</style>