
import axios from 'axios'

const baseUrl = '/api/BusinessPremise/';

export const businessPremiseService = {
    getAllBusinessPremises,
    getSpecificBusinessPremises,
    updateCoordinates
};

async function getAllBusinessPremises() {
    let result = await axios.get(`${baseUrl}GetAllBusinessPremises`);
    return result.data;
}

async function getSpecificBusinessPremises(premises) {
    let result = await axios.get(`${baseUrl}GetSpecificBusinessPremises`, {
        params: {
            premises: premises
        }
    });
    return result.data;
}

async function updateCoordinates(premiseId, coordinates) {
    let result = await axios.put(`${baseUrl}UpdateCoordinates`, coordinates, {
        params: {
            premiseId: premiseId
        }
    });
    return result.data;
}