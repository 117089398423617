<template>

    <div>

        <b-input-group>
            <b-form-input ref="input" id="mapWhisper" v-model="dataInput" placeholder="Vaše lokace" @keydown="inputTextChange" autocomplete="off"></b-form-input>
        </b-input-group>

    </div>

</template>

<script>

    import { required } from "vuelidate/lib/validators";

    export default {
        name: 'MapWhisperTextBox',
        data: function () {
            return {
                localModel: this.model,
                localLocation: this.location
            }
        },
        props: {
            model: {
                default() { },
                type: String,
                required: true
            },
            location: {
                required: false
            }
        },
        computed: {
            dataInput: {
                get: function () {
                    return this.model;
                },
                set: function (newValue) {
                    this.localModel = newValue
                }
            },
            locationInput: {
                get: function () {
                    return this.location;
                },
                set: function (newValue) {
                    this.localLocation = newValue
                }
            }
        },
        mounted() {
        },
        methods: {
            inputTextChange() {

                let thisHelper = this;

                var inputEl = document.getElementById('mapWhisper');
                var suggest = new SMap.Suggest(inputEl);
                suggest.urlParams({
                    // omezeni pro celou CR
                    bounds: "48.5370786,12.0921668|51.0746358,18.8927040"
                });

                suggest.addListener("suggest", function (suggestData) {
                    thisHelper.locationInput = { coords: suggestData.data };
                    thisHelper.localModel = suggestData.data.title + ", " + suggestData.data.secondRow;

                    thisHelper.$emit('update:location', thisHelper.localLocation);
                    thisHelper.$emit('update:model', thisHelper.localModel);
                })
            },
        }
    }

</script>

<style>
    ul.smap-suggest {
        margin-top: 40px;
        width: 100%;
    }
</style>