
export default function ereceiptCode(value) {
    if (!value) return ''

    let result = "";

    for (var i = 0; i < value.length; i++) {
        if (i % 4 == 0) {
            result += " ";
        }

        result += value[i]
    }

    return result
}