<template>
    <div id="add-ereceipt-modal">
        <b-modal id="modal-add-code"
                 ref="modal"
                 title="Přidat kód"
                 @hidden="resetModal"
                 @ok="handleOk"
                 @shown="shownAddCodeFocus"
                 :ok-disabled="okDisabled">

            <template v-slot:modal-cancel>
                Storno
            </template>

            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group :state="validationState"
                              label="Kód"
                              label-for="name-input"
                              :invalid-feedback="validationMessage">

                    <b-form-input id="eReceiptCode" ref="addCodeInput"
                                  v-model.trim="$v.addEItemCode.$model"
                                  :state="validationState"
                                  v-on:keyup="inputChange"
                                  :min-length="12"
                                  autocomplete="off"
                                  :formatter="formatter"
                                  @keydown.space.prevent></b-form-input>
                    <span class="text-danger">{{disabledCameraText}}</span>
                </b-form-group>
            </form>

            <template v-slot:modal-header="{ close }">
                <div id="cameraButtonWrapper">
                    <b-button id="cameraButton" size="sm" :variant="showCamera ? 'danger' : 'info'" @click="switchCamera" :disabled="isCameraEnabled && disabledCameraText">
                        <i class="fa fa-camera"></i>
                    </b-button>
                </div>
                <b-tooltip target="cameraButtonWrapper" triggers="hover" v-if="!isCameraEnabled && disabledCameraText">
                    {{disabledCameraText}}
                </b-tooltip>
                <h5>Přidat kód</h5>
            </template>

            <StreamBarcodeReader v-if="showCamera" @decode="onDecode" @loaded="onInit">
                <div class="loading-indicator text-center" v-if="cameraLoading">
                    <strong>Zapínám kameru...</strong>
                </div>
            </StreamBarcodeReader>


        </b-modal>
    </div>
</template>

<script>
    import { required, maxLength, minLength } from "vuelidate/lib/validators";
    import { eitemCodeValidatorService } from '@/services'
    import { StreamBarcodeReader } from "vue-barcode-reader";

    var DetectRTC = require('detectrtc');

    export default {
        name: 'AddItemCodeModal',
        components: {
            StreamBarcodeReader,
        },
        data: function () {
            return {
                addEItemCode: "",
                validationState: null,
                validationMessage: "",
                modalFormValidFromServer: false,
                showCamera: false,
                isCameraEnabled: false,
                disabledCameraText: null,
                cameraLoading: false,
                okDisabled: false
            }
        },
        props: {
            eDocuments: {
                default: () => [],
                type: Array,
                required: false
            },
            eReceiptIsAllowed: {
                required: false
            },
            eVoucherIsAllowed: {
                required: false
            }
        },
        validations: {
            addEItemCode: {
                required,
                lengthValidity(value) {
                    const length = value.length;
                    return length === 9 || length === 12; // 9 eVeoucher / 12 ePrescription
                },
                isServerValid(value) {
                    return this.modalFormValidFromServer
                }
            }
        },
        methods: {
            formatter(value) {
                return value.toUpperCase()
            },
            async onInit(promise) {
                this.cameraLoading = true

                try {
                    await promise
                } catch (error) {
                    this.handleError(error)
                }
                finally {
                    this.cameraLoading = false
                }
            },
            handleError(error) {
                let errorTypes = {
                    NotAllowedError: "CHYBA: musíte udělit oprávnění pro přístup ke kameře",
                    NotFoundError: "CHYBA: zařízení nemá žádnou kameru",
                    NotSupportedError: "CHYBA: vyžadován zabezpečený kontext (HTTPS, localhost)",
                    NotReadableError: "CHYBA: zkontrolujte, zda se kamera již nepoužívá",
                    OverconstrainedError: "CHYBA: vaše kamera je nevhodná",
                    StreamApiNotSupportedError: "CHYBA: Stream API není v tomto prohlížeči podporován"
                }
                this.disabledCameraText = errorTypes[error.name] || "Neznámá chyba"
            },
            resetModal() {
                this.addEItemCode = ''
                this.validationState = null
            },
            shownAddCodeFocus() {
                this.$refs.addCodeInput.focus()
                this.okDisabled = true;
            },
            async handleOk(bvModalEvt) {
                bvModalEvt.preventDefault()
                await this.handleSubmit()
            },
            async handleSubmit() {
                this.okDisabled = true;

                let codes = this.addEItemCode.split(",");
                let promises = codes.map(code => this.validateAndAddCode(code));

                try {
                    await Promise.all(promises)

                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-add-code')
                    })
                } catch (e) {
                    console.log("Chyba", e);
                    this.okDisabled = true;
                } finally {
                    this.okDisabled = true;
                }
            },
            async validateAndAddCode(code) {
                code = code.replace(/\s/g, '');
                if (this.isCodeAlreadyAdded(code)) {
                    this.setValidationMessage("Kód je již na seznamu.", false)
                    return
                }

                this.addEItemCode = code
                let result = await this.checkFormValidity(code);

                if (!result.isValid) {
                    return
                }

                this.eDocuments.push({ code: code, eItemShortcut: result.eItemShortcut });
            },
            isCodeAlreadyAdded(code) {
                return this.eDocuments.some(item => item.code === code)
            },
            async checkFormValidity(code) {
                this.okDisabled = true;
                this.$v.addEItemCode.$touch();

                let result = await eitemCodeValidatorService.isEItemCodeValid(code);
                this.modalFormValidFromServer = result.data.isValid;
                let valid = result.data.isValid && !this.$v.addEItemCode.$invalid

                if (!valid) {
                    this.setValidationMessageByError()
                }
                else {
                    this.okDisabled = false;
                }

                this.validationState = valid

                return result.data
            },
            setValidationMessageByError() {
                if (!this.$v.addEItemCode.required) {
                    this.setValidationMessage("Kód musí být vyplněný.", false)
                }
                else if (!this.$v.addEItemCode.lengthValidity) {
                    this.setValidationMessage("Délka kódu neodpovídá ePoukazu ani eReceptu.", false)
                }
                else if (!this.$v.addEItemCode.isServerValid) {
                    this.setValidationMessage("Kód není validní.", false)
                }
            },
            setValidationMessage(message, valid) {
                this.validationMessage = message
                this.validationState = valid
                this.okDisabled = !valid;
            },
            checkEVoucher(code) {
                if (code.length === 9 && !this.eVoucherIsAllowed) {
                    this.setValidationMessage("Vámi vybraná provozovna nepodporuje ePoukazy.", false)
                    return false;
                }

                return true;
            },
            checkEReceipt(code) {
                if (code.length === 12 && !this.eReceiptIsAllowed) {
                    this.setValidationMessage("Vámi vybraná provozovna nepodporuje eRecepty.", false)
                    return false;
                }

                return true;
            },
            async inputChange() {
                this.modalFormValidFromServer = false;

                // Předpokládáme, že this.$v.addEItemCode je řetězec
                let codes = String(this.$v.addEItemCode.$model).split(',').map(s => s.trim());
                let valid = true;

                console.log(codes);

                for (let code of codes) {
                    if (code.length === 9) {
                        if (!this.checkEVoucher(code)) {
                            valid = false;
                            break;
                        }
                    } else if (code.length === 12) {
                        if (!this.checkEReceipt(code)) {
                            valid = false;
                            break;
                        }
                    }
                    else {
                        this.setValidationMessage("Kód musí mít 9 nebo 12 znaků.", false);
                        valid = false;
                        break;
                    }

                    let result = await eitemCodeValidatorService.isEItemCodeValid(code);
                    if (!result.data.isValid) {
                        this.setValidationMessage("Kód: " + code + " není validní", false);
                        valid = false;
                        break; // Pokud chcete přerušit po prvním nevalidním kódu, nebo odstraňte tento break, pokud chcete ověřit všechny kódy
                    }
                }

                this.modalFormValidFromServer = valid;

                this.$v.addEItemCode.$touch();

                if (valid) {
                    this.setValidationMessage("Všechny kódy jsou validní.", true);
                } else {
                    // Tato zpráva se může zdát redundantní, protože byla nastavena v cyklu,
                    // ale můžete ji přizpůsobit pro obecné shrnutí po ověření všech kódů.
                }

                this.validationState = valid;
            }
,
            async onDecode(result) {
                this.showCamera = false;

                // pokud byl načten qr kod, tak zacina vzdy https, odtud jsme schopni si vytahnout eRecept kod
                if (result.startsWith("https") && result.includes('erp?')) {
                    this.addEItemCode = result.substring(
                        result.lastIndexOf("i=") + 2,
                        result.lastIndexOf("&d"))
                }
                else if (result.startsWith("https") && result.includes('epo?')) {
                    this.addEItemCode = result.substring(
                        result.lastIndexOf("i=") + 2,
                        result.lastIndexOf("&s"))
                }
                else if (result.startsWith("PRUV")) {
                    let pattern = /SERP:(?<erecept>.{12})\\*/gm;
                    let matches = result.match(pattern);

                    let matchesResult = _.map(matches, function (match) {
                        return match.replace("SERP:", "");
                    }).join(',');

                    this.addEItemCode = matchesResult;
                }
                else {
                    this.addEItemCode = result;
                }

                await this.inputChange();
            },
            switchCamera() {
                this.showCamera = !this.showCamera;
                // Kontrola kamery se provede až při pokusu o její aktivaci
                if (this.showCamera) {
                    this.getCameraInfo();
                }
            },
            checkIfCameraAvailable() {
                DetectRTC.load(() => {
                    this.isCameraEnabled = DetectRTC.isWebRTCSupported && DetectRTC.hasWebcam;
                    if (!this.isCameraEnabled) {
                        this.disabledCameraText = 'Kamera není dostupná';
                    }
                });
            },
            getCameraInfo() {
                let thisHelper = this;

                DetectRTC.load(function () {
                    if (DetectRTC.hasWebcam && DetectRTC.isWebsiteHasWebcamPermissions) {
                        thisHelper.isCameraEnabled = true;
                        thisHelper.disabledCameraText = null;
                    }
                    else {
                        thisHelper.isCameraEnabled = false;
                        thisHelper.showCamera = false;

                        if (!DetectRTC.hasWebcam) {
                            thisHelper.disabledCameraText = "Vaše zařízení nemá připojenou kameru.";
                        }

                        if (!DetectRTC.isWebsiteHasWebcamPermissions) {
                            thisHelper.disabledCameraText = "Nebyl udělen souhlas pro použití kamery na této stránce.";
                        }
                    }

                });
            },

        },
        created() {
            //this.checkIfCameraAvailable();
        }
    }
</script>