
import { businessPremiseService } from '@/services'

const state = {
    allBusinessPremises: {
        status: {
            loading: false
        },
        businessPremises: []
    },
    specificBusinessPremises: {
        status: {
            loading: false
        },
        specificBusinessPremises: []
    },
    updateCoordinates: {
        status: {
            loading: false
        },
        premiseId: 0,
        coodinates: {}
    }
}

const mutations = {

    getAllBusinessPremisesRequest() {
        state.allBusinessPremises.status.loading = true;
    },
    getAllBusinessPremisesSuccess(state, businessPremises) {
        state.allBusinessPremises.status.loading = false;
        state.allBusinessPremises.businessPremises = businessPremises;
    },
    getAllBusinessPremisesFailure(state) {
        state.allBusinessPremises.status.loading = false;
        state.allBusinessPremises.businessPremises = [];
    },

    getSpecificBusinessPremisesRequest() {
        state.specificBusinessPremises.status.loading = true;
    },
    getSpecificBusinessPremisesSuccess(state, businessPremises) {
        state.specificBusinessPremises.status.loading = false;
        state.specificBusinessPremises.specificBusinessPremises = businessPremises;
    },
    getSpecificBusinessPremisesFailure(state) {
        state.specificBusinessPremises.status.loading = false;
        state.specificBusinessPremises.specificBusinessPremises = [];
    },

    updateCoordinatesRequest() {
        state.updateCoordinates.status.loading = true;
    },
    updateCoordinatesSuccess(state, insertedMember) {
        state.updateCoordinates.status.loading = false;
        state.updateCoordinates.coodinates = insertedMember;
        state.updateCoordinates.premiseId = 0;
    },
    updateCoordinatesFailure(state) {
        state.updateCoordinates.status.loading = false;
        state.updateCoordinates.coodinates = {};
        state.updateCoordinates.premiseId = 0;
    },
}

const actions = {

    getAllBusinessPremises: async function ({ commit }) {
        commit('getAllBusinessPremisesRequest');
        await businessPremiseService.getAllBusinessPremises()
            .then(
                businessPremises => {
                    commit('getAllBusinessPremisesSuccess', businessPremises);
                },
                error => {
                    commit('getAllBusinessPremisesFailure', error);
                }
            );
    },

    getSpecificBusinessPremises: async function ({ commit }, premises) {
        commit('getSpecificBusinessPremisesRequest');
        await businessPremiseService.getSpecificBusinessPremises(premises)
            .then(
                businessPremises => {
                    commit('getSpecificBusinessPremisesSuccess', businessPremises);
                },
                error => {
                    commit('getSpecificBusinessPremisesFailure', error);
                }
            );
    },

    // aktualizuje souřadnice
    updateCoordinates: async function ({ commit }, params) {
        commit('updateCoordinatesRequest');
        await businessPremiseService.updateCoordinates(params.premiseId, params.coordinates)
            .then(
                updatedCoordinates => {
                    commit('updateCoordinatesSuccess', updatedCoordinates);
                },
                error => {
                    commit('updateCoordinatesFailure', error);
                }
            );
    },

}

export const businessPremise = {
    namespaced: true,
    state,
    actions,
    mutations
};