<template>

    <div>
        <b-form-group id="igStreet">
            <b-form-input id="street"
                          name="street"
                          placeholder="Ulice *"
                          v-model="dataInput"
                          :state.sync="validInput"
                          v-on:keyup="inputTextChange"
                          aria-describedby="streetFeedback"></b-form-input>
            <b-form-invalid-feedback id="streetFeedback">Musíte vyplnit ulici.</b-form-invalid-feedback>
        </b-form-group>
    </div>

</template>

<script>

    export default {
        name: 'streetFormInput',
        data: function () {
            return {
                localModel: this.model,
                localValid: this.valid
            }
        },
        props: {
            model: {
            },
            valid: {
            }
        },
        computed: {
            dataInput: {
                get: function () {
                    return this.model;
                },
                set: function (newValue) {
                    this.localModel = newValue
                }
            },
            validInput: {
                get: function () {
                    return this.valid;
                },
                set: function (newValue) {
                    this.localValid = newValue
                }
            }
        },
        mounted() {
        },
        methods: {
            inputTextChange() {
                this.$emit('update:model', this.localModel);
            }
        }
    }

</script>