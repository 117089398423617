<template>

    <div>

        <b-form-select v-model="dataInput" :options="sortedBusinessPremise" @change="selectChange" :disabled="disabled">
            <template v-slot:first>
                <b-form-select-option :value="null" disabled>-- {{defaultSelectText}} --</b-form-select-option>
            </template>
        </b-form-select>

    </div>

</template>

<script>

    import { required } from "vuelidate/lib/validators";
    import { geolocationService } from '@/services'
    import { businessPremiseService } from "../services";

    export default {
        name: 'BusinessPremiseSelect',
        data: function () {
            return {
                localModel: this.model,
                localLocation: this.location,
                localGdprUrl: this.gdprUrl,
                defaultSelectText: "Vyberte lékárnu",
                dataFromServer: []
            }
        },
        props: {
            model: {
                default() { }
            },
            location: {
                required: false
            },
            gdprUrl: {
                required: false
            },
            disabled: {
                required: false
            },
            eReceiptIsAllowed: {
                required: false,
                default: false
            },
            eVoucherIsAllowed: {
                required: false,
                default: false
            }
        },
        asyncComputed: {
            sortedBusinessPremise() {
                let thisHelper = this;

                thisHelper.sortedBusinessPremise = _.sortBy(thisHelper.businessPremises, [function (businessPremise) { return businessPremise.lengthInMeters; }]);

                if (this.location) {
                    let promises = [];
                    _.forEach(thisHelper.businessPremises, function (businessPremise) {
                        promises.push(new Promise((resolve, reject) => {
                            thisHelper.calculateLenghtBetweenLocations(businessPremise, function () {
                                resolve();
                            })
                        }))
                    })
                    return Promise.all(promises).then(function () {
                        _.forEach(thisHelper.businessPremises, function (businessPremise) {
                            let bpFromServer = _.find(thisHelper.dataFromServer, function (bp) { return bp.id == businessPremise.value });
                            businessPremise.text = bpFromServer.name + ", " + bpFromServer.address;
                            businessPremise.text += " (" + thisHelper.getLengthAsText(businessPremise.lengthInMeters) + ")";
                        });
                        return _.sortBy(thisHelper.businessPremises, [function (businessPremise) { return businessPremise.lengthInMeters; }]);
                    });
                } else {
                    return _.sortBy(thisHelper.businessPremises, [function (businessPremise) { return businessPremise.lengthInMeters; }]);
                }
            },
        },
        computed: {
            businessPremises() {

                let businessPremises = [];
                let thisHelper = this;
                thisHelper.dataFromServer = [];

                if (thisHelper.$route.query['provozovny']) {
                    thisHelper.dataFromServer = thisHelper.$store.state.businessPremise.specificBusinessPremises.specificBusinessPremises;
                }
                else {
                    thisHelper.dataFromServer = thisHelper.$store.state.businessPremise.allBusinessPremises.businessPremises;
                }

                businessPremises = _.map(thisHelper.dataFromServer, function (item) {
                    return {
                        value: item.id,
                        text: item.name + ", " + item.address,
                        coords: item.coords,
                        gdprUrl: item.gdprAgreementLink,
                        ePrescriptionEnabled: item.ePrescriptionEnabled,
                        eVoucherEnabled: item.eVoucherEnabled
                    };
                });

                if (businessPremises.length == 0) {
                    thisHelper.defaultSelectText = "Nebyly nalezeny žádné lékárny"
                }
                if (businessPremises.length == 1) {
                    thisHelper.localModel = thisHelper.dataFromServer[0].id;
                    thisHelper.$emit('update:model', thisHelper.localModel)
                    thisHelper.$emit('update:eReceiptIsAllowed', businessPremises[0].ePrescriptionEnabled);
                    thisHelper.$emit('update:eVoucherEnabled', businessPremises[0].eVoucherEnabled);
                }
                else {
                    thisHelper.defaultSelectText = "Vyberte lékárnu";
                }

                return businessPremises;

            },
            dataInput: {
                get: function () {
                    return this.model;
                },
                set: function (newValue) {
                    this.localModel = newValue
                }
            },
        },
        mounted() {
            if (this.$route.query['provozovny']) {
                console.log("veuCast");
                this.$store.dispatch('businessPremise/getSpecificBusinessPremises', this.$route.query['provozovny']);
            }
            else {
                this.$store.dispatch('businessPremise/getAllBusinessPremises');
            }
        },
        methods: {
            calculateLenghtBetweenLocations(item, callback) {

                let thisHelper = this;

                let customerCoords = SMap.Coords.fromWGS84(this.location.coords.longitude, this.location.coords.latitude);
                if (!item.coords) {
                    geolocationService.findLocationByString(item.text, function (result) {
                        if (result) {
                            thisHelper.updateCoordsForBusinesPremisse(item.value, result[0].coords);
                            item.coord = result[0].coords
                            item.lengthInMeters = geolocationService.distanceInKmBetweenEarthCoordinatesInM(thisHelper.location.coords.latitude, thisHelper.location.coords.longitude, result[0].coords.y, result[0].coords.x);
                            callback();
                        }
                    })
                }
                else {
                    item.lengthInMeters = geolocationService.distanceInKmBetweenEarthCoordinatesInM(this.location.coords.latitude, this.location.coords.longitude, item.coords.latitude, item.coords.longitude);
                    callback();
                }
            },
            updateCoordsForBusinesPremisse(premiseId, coords) {
                this.$store.dispatch("businessPremise/updateCoordinates", { premiseId: premiseId, coordinates: { latitude: coords.y, longitude: coords.x } })
                    .then(result => {
                        //console.log('Souradnice pro provozovnu s id ' + premiseId + ' byly uspesne aktualizovany');
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    });
            },
            getLengthAsText(length) {
                if (length != undefined && length != null) {
                    if (length < 1000) {
                        return length.toFixed(0) + " m";
                    }
                    else {

                        let inKm = length / 1000;

                        return inKm.toFixed(1) + " km"
                    }
                }
            },
            selectChange() {
                this.$emit('update:model', this.localModel);

                let thisHelper = this;

                let selected = _.find(thisHelper.businessPremises, function (item) {
                    return item.value == thisHelper.localModel
                });

                let resultUrl = "https://www.lekis.cz/podpora/rezervace-ereceptu/";

                if (selected.gdprUrl != undefined && selected.gdprUrl != "") {
                    resultUrl = selected.gdprUrl;
                }

                this.$emit('update:gdprUrl', resultUrl);
                this.$emit('update:eVoucherIsAllowed', selected.eVoucherEnabled)
                this.$emit('update:eReceiptIsAllowed', selected.ePrescriptionEnabled)
            }
        }
    }

</script>