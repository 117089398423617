<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>


export default {
  name: 'app',
}
</script>

<style>
    .app {
        background: -webkit-linear-gradient(left, #3931af, #00c6ff);
    }
    .footer-text {
        color: black !important
    }
</style>
