import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import ereceiptFormPage from '@/components/ereceiptFormPage.vue'
import summaryPage from '@/components/summaryPage.vue'
import ereceiptFormPageForIframe from '@/components/ereceiptFormPageForIframe.vue'

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            name: 'home',
            component: ereceiptFormPage
        },
        {
            path: '/formular',
            name: 'formOnly',
            component: ereceiptFormPageForIframe,
            props: { eDocuments: [] }
        },
        {
            path: '/shrnuti',
            name: 'summary',
            component: summaryPage
        }
    ]
})
