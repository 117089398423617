<template>

    <div>

        <div v-if="_.isEmpty(summaryData)">
            <h1>Shrnutí objednávky není možné zobrazit</h1>
            <p>Je nám líto, ale není možné zobrazit shrnutí objednávky, protože žádná objednávka nebyla zadána.</p>
            <b-button variant="success" class="float-right" to="/">Další objednávka</b-button>
        </div>
        <div v-else>
            <h1>Vaše objednávka byla úspěšně odeslána</h1>
            <p>Na Vámi zadaný email bude zaslaná zpráva se shrnutím objednávky.</p>
            <div class="row">
                <div class="col-md-8">
                    <h5>Osobní údaje</h5>
                    <p>
                        <span>Lékárna:</span> <strong>{{summaryData.businessPremiseText}}</strong><br />
                        <span>Jméno a příjmení:</span> <strong>{{summaryData.fullName}}</strong><br />
                        <span>Email:</span> <strong>{{summaryData.email}}</strong><br />
                        <span>Telefon:</span> <strong>{{summaryData.phoneNumber}}</strong><br />
                    </p>
                    <h5>Doručovací údaje</h5>
                    <p>
                        <span>Ulice:</span> <strong>{{summaryData.street}}</strong><br />
                        <span>Město:</span> <strong>{{summaryData.city}}</strong><br />
                        <span>PSČ:</span> <strong>{{summaryData.postalCode}}</strong><br />
                    </p>
                    <p>Možnost zaměnit léky za levnější generika: <strong>{{summaryData.allowChangeMedicamentForCheaper == true? 'Ano' : 'Ne'}}</strong></p>
                    <p>Vydat všechna opakování u opakovacích eReceptů: <strong>{{summaryData.allowReleaseAllReps == true? 'Ano' : 'Ne'}}</strong></p>
                    <span>Poznámka:</span> <strong>{{summaryData.note}}</strong>
                </div>
                <div class="col-md-4">
                    <h5>eRecepty</h5>
                    <p>
                        <span v-for="item in summaryData.prescriptions" :key="item.id">{{item.code | ereceiptCode}}<br /></span>
                    </p>
                </div>
            </div>
            <b-button variant="success" class="float-right" to="/">Další objednávka</b-button>
        </div>


    </div>

</template>

<script>

    export default {
        name: 'voticeSummary',
        data: function () {
            return {
                summaryData: {}
            }
        },
        props: {
        },
        computed: {
        },
        mounted() {
            this.summaryData = this.$store.state.reservation.createReservation.createdReservation;
        }
    }

</script>