
import axios from 'axios'

const baseUrl = '/api/reservation/';

export const reservationService = {
    createReservation
};

async function createReservation(data) {
    let result = await axios.post(`${baseUrl}CreateReservation`, data);
    return result.data;
}