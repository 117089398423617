<template>

    <div>

        <b-form-group id="igPhoneNumber">
            <b-form-input id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Telefon *"
                          v-model="dataInput"
                          :state.sync="validInput"
                          aria-describedby="phoneNumberFeedback"
                          @keypress="isPhonuNumberChar($event)"
                          v-on:keyup="inputTextChange"></b-form-input>
            <b-form-invalid-feedback id="phoneNumberFeedback">Musíte vyplnit validní telefonní číslo.</b-form-invalid-feedback>
        </b-form-group>
    </div>

</template>

<script>

    export default {
        name: 'phoneNumberFormInput',
        data: function () {
            return {
                localModel: this.model,
                localValid: this.valid
            }
        },
        props: {
            model: {
            },
            valid: {
            }
        },
        computed: {
            dataInput: {
                get: function () {
                    return this.model;
                },
                set: function (newValue) {
                    this.localModel = newValue
                }
            },
            validInput: {
                get: function () {
                    return this.valid;
                },
                set: function (newValue) {
                    this.localValid = newValue
                }
            }
        },
        mounted() {
        },
        methods: {
            inputTextChange() {
                this.$emit('update:model', this.localModel);
            },
            isPhonuNumberChar(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 43) {
                    evt.preventDefault();
                } else {                   
                    return true;
                }
            }
        }
    }

</script>