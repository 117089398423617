<template>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="lekis-input-group">
                <h3>Kontaktní údaje</h3> 
                <full-name-input :model.sync="$v.formData.fullName.$model" :valid="validateState('fullName')"></full-name-input>
                <email-input :model.sync="$v.formData.email.$model" :valid="validateState('email')"></email-input>
                <phone-input :model.sync="$v.formData.phoneNumber.$model" :valid="validateState('phoneNumber')"></phone-input>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="lekis-input-group">
                <h3>Poznámka</h3>
                <b-form-group id="igNote">
                    <b-form-textarea id="note"
                                     name="note"
                                     placeholder="Zde napište poznámku"
                                     v-model="$v.formData.note.$model"></b-form-textarea>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>

    import { required, email } from "vuelidate/lib/validators";
    import phoneInput from '@/components/formComponents/phoneNumberFormInput'
    import emailInput from '@/components/formComponents/emailFormInput'
    import fullNameInput from '@/components/formComponents/fullNameFormInput'

    export default {
        props: ['formData'],
        components: {
            fullNameInput,
            emailInput,
            phoneInput
        },
        validations: {
            formData: {
                fullName: { required },
                email: { required, email },
                phoneNumber: {
                    required,
                    isPhoneNumber(value) {
                        let phoneRegex = /^(\+\d{3})? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/;
                        if (!value) {
                            return false;
                        }
                        let m = value.match(phoneRegex)
                        if (m) {
                            return true;
                        }

                        return false;
                    }
                },
                note: {}
            }
        },
        methods: {
            validateState(ref) {
                const { $dirty, $error } = this.$v.formData[ref];
                this.valid = $dirty ? !$error : null;
                return $dirty ? !$error : null;
            },
            validateForm() {
                this.$v.formData.$touch();
                return !this.$v.formData.$invalid;
            }
        }
    }
</script>
